import React from 'react'
import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import { Logo, media } from 'ui'

import { MENU, SOCIAL } from 'content'

const YEAR = new Date().getFullYear()

const Footer = () => (
  <Wrapper>
    <Inner>
      <LogoBox>
        <Logo kind='full' height='3.5rem' />

        <LegalText>
          Brainn.co Servicos de Tecnologia LTDA
          <br />
          CNPJ 28.180.700/0001-24
          <br />
          &copy; {YEAR} All rights reserved
        </LegalText>
      </LogoBox>
      <BrainnColumn>
        <Title>Brainn Co.</Title>
        <Links>
          {MENU.map(({ name, description, url, external, footerMenu }) => (
            (footerMenu && <Item key={name}>
              <Link 
                to={url} 
                aria-label={description}
                external={external}
              >
                {name}
              </Link>
            </Item>)
          ))}
        </Links>
      </BrainnColumn>
      <Headquarter>
        <Title>Headquarter</Title>
        <Address>
          376 Cap. Antônio Rosa St.
          <br />
          Sao Paulo, SP / Brazil
        </Address>
        <Link
          href='https://maps.app.goo.gl/wQxaGjzuGbJ7A3BBA'
          target='_blank'
          rel='noopener noreferrer nofollow'
          external
        >
          Directions
        </Link>
      </Headquarter>
      <Column>
        <Title>Follow us</Title>
        <Links>
          {SOCIAL.map(({ name, url, visible }) => (
            (visible && <Item key={name}>
              <Link 
                to={url}
                target='_blank'
                rel='noopener noreferrer nofollow'
                external
              >
                {name}
              </Link>
            </Item>)
          ))}
        </Links>
      </Column>
    </Inner>
  </Wrapper>
)

const Wrapper = styled.footer`
  padding: 10rem 0;
  background: var(--color-primary);

  ${media.lessThan('phone')`
    position: relative;
    padding: 6rem 0 10rem;
  `}
`

const Inner = styled.div`
  max-width: var(--size-inner);
  margin: 0 auto;
  width: 100%;
  color: var(--color-secondary);
  display: flex;
  justify-content: space-between;

  ${media.lessThan('mediumDesktop')`
    padding: 0 var(--spacing-medium);
  `}

  ${media.lessThan('phone')`
    display: block;
  `}
`

const LogoBox = styled.div`
  flex-basis: 30%;

  ${media.lessThan('phone')`
    padding-bottom: 5rem;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    padding-right: 2rem;
  `}
`

const BrainnColumn = styled.div`
  flex-basis: 20%;

  ${media.lessThan('phone')`
    margin-bottom: 3rem;
  `}
`

const Column = styled.div`
  flex: 1;

  ${media.lessThan('phone')`
    margin-bottom: 4rem;
    text-align: center;
  `}
`

const Headquarter = styled.div`
  flex-basis: 26%;

  ${media.lessThan('desktop')`
    flex-basis: 30%;
  `}

  ${media.lessThan('phone')`
    margin-bottom: 4rem;
    text-align: center;
  `}
`

const Title = styled.h4`
  font-family: var(--font-title);
  color: var(--color-secondary);
  font-size: 2rem;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  padding-bottom: .5rem;

  ${media.lessThan('phone')`
    text-align: center;
  `}
`

const Links = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`

const Item = styled.li`
  ${media.lessThan('phone')`
    text-align: center;
  `}
`

const Address = styled.address`
  color: var(--color-text);
  font-size: 1.6rem;
  line-height: 1.8;
  font-style: normal;
  padding-top: .5rem;

  ${media.lessThan('phone')`
    text-align: center;
  `}
`

const LegalText = styled.p`
  margin: 0;
  padding: 4rem 0 0;
  color: #777;
  font-size: 1.4rem;
  line-height: 1.6;
  opacity: .7;

  ${media.lessThan('phone')`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 10rem;
    width: 100%;
    padding: 0;
    text-align: center;
    color: var(--color-text);
    line-height: 1.8;
  `}
`

const Link = styled(({ ...props }) => <GatsbyLink {...props} />)`
  display: inline-block;
  padding: 1rem 0;
  color: var(--color-text);
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: normal;
  transition: opacity .1s ease, border .1s ease;
  border-bottom: 2px solid rgba(255, 255, 255, .1);
  padding-bottom: .2em;

  &:hover {
    color: var(--color-secondary);
    border-bottom-color: var(--color-secondary);
  }

  ${({ external }) => external && css`
    &:after {
      content: '\\279E';
      font-size: .8em;
      transform: rotate(-45deg);
      display: inline-block;
      padding-left: .4em;
      vertical-align: bottom;
    }
  `}
`

export { Footer }
