import React from 'react'

export const GreenFieldIcon = ({ color = '#000' }) => (
  <svg width='108' height='8' viewBox='0 0 108 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      id='green-field-icon' 
      d='M0 4H108' 
      stroke={color} 
      strokeWidth='8'
    />
  </svg>
)

export const BrownFieldIcon = ({ color = '#000' }) => (
  <svg width='108' height='34' viewBox='0 0 108 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path 
      id='brown-field-icon'
      d='M0 22.0002C13 22.0002 19 -3.05176e-05 32.5 10C38.6639 14.5659 43.3701 27.1128 51 29C60.0809 31.2462 66.7619 13.4776 76 7.5C93 -3.50001 97.5 22.0002 108 22.0002' 
      stroke={color} 
      strokeWidth='8'
    />
  </svg>
)
