import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { useInView } from 'react-intersection-observer'

import { Technologies } from 'partials'

import { 
  media, 
  Text,
  SectionHeader,
  GreenFieldIcon,
  BrownFieldIcon,
} from 'ui'

const WhatWeDo = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  return (
    <Wrapper ref={ref}>
      <Inner>
        <SectionHeader
          title='What we do'
          white
        />

        <Services ref={ref}>
          <Column>
            <Icon animation={inView}>
              <GreenFieldIcon color={'#f36'} />
            </Icon>
            <Title>Green field</Title>
            <Description>
              We help you to build a completely new and taylor made digital product from scratch, web or mobile, from design to execution and delivery.
            </Description>
          </Column>
          <Column>
            <Icon animation={inView}>
              <BrownFieldIcon color={'#f36'} />
            </Icon>
            <Title>Brown field</Title>
            <Description>
              We help you to improve your digital product from where it is, web or mobile, from understanding the problems to execution and delivery.
            </Description>
          </Column>
        </Services>

        <Technologies />
      </Inner>
    </Wrapper>
  )
}

const line = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const Wrapper = styled.section`
  background: var(--color-primary);
  padding: 8rem 0;
  margin: 4rem auto 0;

  ${media.lessThan('phone')`
    padding: 6rem 0;
  `}
`

const Inner = styled.div`
  max-width: var(--size-inner);
  margin: 0 auto;
  width: 100%;
  text-align: center;
`

const Services = styled.div`
  display: flex;
  width: 100%;
  padding-top: 5rem;

  ${media.lessThan('phone')`
    display: block;
    padding: 0 4rem;
  `}
`

const Column = styled.div`
  flex: 1;

  ${media.lessThan('phone')`
    margin: 3rem 0;
  `}
`

const Icon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 9rem;
  margin: 0 auto;

  svg {
    width: 100%;
    height: auto;
  }

  ${({ animation }) => animation && css`
    #green-field-icon,
    #brown-field-icon {
      --line-size: 200;
      stroke-dasharray: var(--line-size);
      stroke-dashoffset: var(--line-size);
      animation: ${line} 1.3s linear forwards 1s;
    }
  `}

  ${media.lessThan('phone')`
    width: 8rem;
    height: 6rem;
  `}
`

const Title = styled.h3`
  font-size: 3.2rem;
  color: var(--color-secondary);
  font-family: var(--font-title);
  padding: .5rem 0;
  margin: 0;

  ${media.lessThan('phone')`
    font-size: 2.6rem;
  `}
`

const Description = styled(Text)`
  color: var(--color-secondary);
  margin: 1rem auto;
  max-width: 70%;
  font-size: 1.8rem;

  ${media.lessThan('phone')`
    max-width: 100%;
    font-size: 1.6rem;
  `}
`

export { WhatWeDo }