export const SOCIAL = [
  {
    name: 'Linkedin',
    url: 'https://www.linkedin.com/company/brainnco',
    visible: true,
  },
  {
    name: 'Instagram',
    url: 'https://instagram.com/brainn_co',
    visible: true,
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/brainn_co',
    visible: false,
  },
  {
    name: 'Github',
    url: 'https://github.com/brainnco',
    visible: true,
  },
  {
    name: 'Glassdoor',
    url: 'https://www.glassdoor.com.br/Avalia%C3%A7%C3%B5es/Brainn-co-Avalia%C3%A7%C3%B5es-E2798153.htm',
    visible: true,
  },
  {
    name: 'YouTube',
    url: 'https://youtube.com/brainnco',
    visible: false,
  },
]