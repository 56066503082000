import { generateMedia } from 'styled-media-query'

export const media = generateMedia({
  wideDesktop: '2500px',
  largestDesktop: '1900px',
  largeDesktop: '1600px',
  mediumDesktop: '1310px',
  desktop: '1200px',
  tablet: '900px',
  phone: '600px',
})