import React from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'

import { media } from 'ui'

export const SectionHeader = ({ title, description, pretitle, white }) => (
  <Wrapper>
    {pretitle && <PreTitle>{pretitle}</PreTitle>}
    <SectionTitle white={white}>{title}</SectionTitle>
    <SectionDescription white={white}>{description}</SectionDescription>
  </Wrapper>
)

SectionHeader.propTypes = { 
  title: t.string.isRequired,
  description: t.string,
}

export const PreTitle = styled.p`
  margin: 0;
  padding: 1rem 0;
  color: var(--color-accent);
  font-family: var(--font-default);
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .1em;
`

export const Title = styled.h2`
  margin: 0;
  color: var(--color-primary);
  font-family: var(--font-title);
  font-size: 4rem;
`

export const SectionTitle = styled.h2`
  position: relative;
  margin: 0;
  color: ${({ white }) => white ? css`var(--color-secondary)` : css`var(--color-primary)`};
  font-family: var(--font-title);
  font-size: 4.4rem;
  text-align: center;

  ${media.lessThan('phone')`
    font-size: 3.2rem;
  `}
`

export const SectionDescription = styled.p`
  position: relative;
  padding: .2rem 0 0 0;
  color: ${({ white }) => white ? css`var(--color-secondary)` : css`var(--color-text)`};
  font-family: var(--font-default);
  font-size: 1.8rem;
  text-align: center;
  line-height: 1.6;
  max-width: 60rem;
  margin: 0 auto;

  ${media.lessThan('phone')`
    font-size: 1.6rem;
  `}
`

const Wrapper = styled.div`
`