import styled from 'styled-components'
import { Link } from 'gatsby'

import { media } from 'ui'

export const Button = styled(Link)`
  position: relative;
  display: inline-block;
  font-weight: 500;
  color: var(--color-secondary);
  background-color: var(--color-accent);
  font-size: 1.6rem;
  text-decoration: none;
  padding: .9em 1.8em;
  cursor: pointer;
  transition: transform .2s ease;
  align-self: center;

  &:hover {
    transform: scale(1.05);
  }

  ${media.lessThan('phone')`
    font-size: 1.4rem;
  `}
`