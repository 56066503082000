import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { useInView } from 'react-intersection-observer'

import { media } from 'ui'

const SayHello = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  return (
    <Wrapper ref={ref} id='contact'>
      <Inner>
        <Column>
          <Text>Say Hello</Text>
        </Column>
        <BarBox>
          <Bar animation={inView} />
        </BarBox>
        <Column>
          <Text>
            <Link href='mailto:hello@brainn.co'>hello@brainn.co</Link>
          </Text>
        </Column>
      </Inner>
    </Wrapper>
  )
}

const showBar = keyframes`
  from {
    width: 0;
  }
  to {
    width: 95%;
  }
`

const Wrapper = styled.section`
  background: var(--color-accent);
  padding: 8rem 0;

  ${media.lessThan('mediumDesktop')`
    padding: 7rem var(--spacing-medium);
  `}

  ${media.lessThan('phone')`
    padding: 3rem 0;
  `}
`

const Inner = styled.div`
  max-width: var(--size-inner);
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${media.lessThan('phone')`
    display: block;
  `}
`

const Column = styled.div`
  display: flex;
  align-items: center;

  ${media.lessThan('phone')`
    display: block;
    text-align: center;
  `}
`

const BarBox = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: 3rem;

  ${media.lessThan('tablet')`
    padding: 1rem;
  `}

  ${media.lessThan('phone')`
    width: 45%;
    margin: 0 auto;
    justify-content: center;
  `}
`

const Bar = styled.div`
  height: .6rem;
  width: 0;
  background: var(--color-secondary);

  ${({ animation }) => animation && css`
    animation: ${showBar} 1s cubic-bezier(0.26, 0.29, 0, 1.01) forwards;
    animation-delay: .6s;
  `}

  ${media.lessThan('phone')`
    height: .3rem;
  `}
`

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 5rem;
  color: var(--color-secondary);
  font-family: var(--font-title);
  font-weight: bold;

  ${media.lessThan('phone')`
    font-size: 3.2rem;
  `}
`

const Link = styled.a`
  text-decoration: none;
  color: inherit;
  transition: opacity .2s ease;
  will-change: opacity;

  &:hover {
    opacity: .9;
  }
`

export { SayHello }