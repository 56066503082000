import React from 'react'
import t from 'prop-types'

const LogoSymbol = ({ color = 'var(--color-secondary)' }) => (
  <svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path 
      d='M43.4086 32.3516C42.7991 31.7422 42.7991 30.75 43.4086 30.1406L47.7135 25.8359C49.2448 24.3047 50.1511 22.1328 49.9792 19.7578C49.6979 15.625 46.0884 12.5 41.9475 12.5H12.5007V50H41.9475C46.0884 50 49.6979 46.875 49.9792 42.7422C50.1433 40.3672 49.2448 38.1953 47.7135 36.6641L43.4086 32.3516ZM37.4864 7.25781C37.1973 3.125 33.5877 0 29.4469 0H0V37.5H9.3755V9.375H37.3379C37.4707 8.69531 37.5332 7.98438 37.4864 7.25781Z' 
      fill={color}
    />
  </svg>
)

const LogoFull = ({ color = 'var(--color-secondary)' }) => (
  <svg width='320' height='64' viewBox='0 0 320 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path 
      d='M55.56 41.41C54.78 40.63 54.78 39.36 55.56 38.58L61.07 33.07C63.03 31.11 64.19 28.33 63.97 25.29C63.61 20 58.99 16 53.69 16H16V64H53.69C58.99 64 63.61 60 63.97 54.71C64.18 51.67 63.03 48.89 61.07 46.93L55.56 41.41ZM47.98 9.29C47.61 4 42.99 0 37.69 0H0V48H12V12H47.79C47.96 11.13 48.04 10.22 47.98 9.29Z' 
      fill={color}
    />
    <path 
      d='M264 32L252 16H238V48H250V32L262 48H276V16H264V32ZM308 16V32L296 16H282V48H294V32L306 48H320V16H308ZM220 48H232V16H220V48ZM187.76 16L175.76 48H187.76L189.26 44H204.26L205.76 48H217.76L205.76 16H187.76ZM192.24 36L196.74 24L201.24 36H192.24ZM125.98 31.42C128.92 30.22 131 27.36 131 24C131 19.58 127.42 16 123 16H96V48H123.5C128.2 48 132 44.2 132 39.5C132 35.68 129.46 32.48 125.98 31.42ZM108 23.5H116.76C118.28 23.5 119.52 24.74 119.52 26.26C119.52 27.78 118.26 29 116.76 29H108V23.5ZM117.24 40.5H108V35H117.24C118.76 35 120 36.24 120 37.76C120 39.28 118.76 40.5 117.24 40.5ZM174 27C174 20.92 169.08 16 163 16H136V48H148V38H150.5L160 48H174.5L163 38C169.08 38 174 33.08 174 27ZM159 31H148V25H159C160.66 25 162 26.34 162 28C162 29.66 160.66 31 159 31Z' 
      fill={color}
    />
  </svg>
)

LogoSymbol.propTypes = { 
  color: t.string,
}

LogoFull.propTypes = { 
  color: t.string,
}

export { LogoSymbol, LogoFull }