import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { Theme } from './theme'

import { Footer } from 'partials'

import { media } from 'ui'

import 'normalize.css'
import './layout.css'

function Layout ({ children, noFooter }) {
  return (
    <>
      <Theme />
      <GlobalStyle />
      {children}
      {!noFooter && <Footer />}
    </>
  )
}

const GlobalStyle = createGlobalStyle`
  #gatsby-noscript {
    background: #111;
    font-size: 1.2rem;
    left: 0;
    opacity: .8;
    padding: 10px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 2;
  }

  ::-moz-selection {
    background: var(--color-primary);
    color: var(--color-secondary);
  }

  ::selection {
    background: var(--color-primary);
    color: var(--color-secondary);
  }

  *, 
  :before, 
  :after {
    box-sizing: border-box;
  }

  html {
    font-size: 10px;

    ${media.greaterThan('tablet')`
      scroll-behavior: smooth;
    `}

    ${media.greaterThan('largestDesktop')`
      font-size: 15px;
    `}
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    background: var(--color-secondary);
    color: var(--color-primary);
    font-variant-ligatures: no-common-ligatures;
    font-size: 10px;
    font-family: var(--font-default);
    min-height: 100vh;
    cursor: default;
  }

  ${media.greaterThan('mediumDesktop')`
    :root {
      --size-main: 90%;
    }
  `}

  ${media.greaterThan('wideDesktop')`
    :root {
      --size-main: 2400px;
    }
  `}
`

export default Layout
