import React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import { media } from 'ui'

import { MENU } from 'content'

const Menu = () => (
  <List>
    {MENU.map(({ name, description, url, mainMenu }) => (
      (mainMenu && <Item key={name}>
        <Link 
          to={url} 
          aria-label={description}
          activeClassName='active'
        >
          {name}
        </Link>
      </Item>)
    ))}
  </List>
)


const List = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
`

const Item = styled.li`
  padding: 0 2rem;

  ${media.lessThan('phone')`
    &:last-child {
      padding-right: 0;
      padding-left: 1rem;
    }
  `}
`

const Link = styled(({ ...props }) => <GatsbyLink {...props} />)`
  position: relative;
  color: var(--color-primary);
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 500;
  transition: opacity .1s ease, border .1s ease;
  border-bottom: 2px solid transparent;
  padding-bottom: .2em;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -10%;
    width: 120%;
    bottom: -1.5rem;
    height: 2rem;
  }
  
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -1rem;
    height: 2px;
    background: var(--color-primary);
    transform: scale(1.1);
    transition: all .2s ease;
    opacity: 0;
  }

  &:hover,
  &.active {
    &:after {
      opacity: 1;
      bottom: -.4rem;
      transform: scale(1);
    }
  }
`

export { Menu }