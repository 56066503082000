import React from 'react'
import styled from 'styled-components'

import { 
  SectionHeader, 
  Text,
  media,
} from 'ui'

const Investors = () => (
  <Wrapper>
    <Box>
      <Inner>
        <SectionHeader
          title='Our believers'
          description='Lorem ipsum dolor sit amet'
          white
        />
        
        <InvestorsArea>
          <Item>
            <Image>
              <img src='/images/investors/provence.png' alt='' />
            </Image>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
            </Description>
          </Item>
          <Item>
            <Image>
              <img src='/images/investors/acnext.png' alt='' />
            </Image>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
            </Description>
          </Item>
        </InvestorsArea>

      </Inner>
    </Box>
  </Wrapper>
)

const Wrapper = styled.section`
`

const Box = styled.div`
  background: var(--color-primary);
  padding: 8rem 0;
  //max-width: calc(var(--size-main) + 1px);
  margin: 0 auto;
`

const Inner = styled.div`
  max-width: var(--size-main);
  margin: 0 auto;

  ${media.lessThan('tablet')`
    padding: 0 var(--spacing-medium);
  `}
`

const InvestorsArea = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10rem 0 5rem;

  ${media.lessThan('phone')`
    display: block;
    padding: 5rem 0 0;
  `}
`

const Item = styled.div`
  flex: 1;
  padding: 2rem 0 3rem;

  &:first-child {
    border-right: 1px solid rgba(255, 255, 255, .09);

    ${media.lessThan('phone')`
      border-right: 0;
    `}
  }
`

const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;

  img {
    max-height: 9rem;
    max-width: 100%;
  }
`

const Description = styled(Text)`
  max-width: 80%;
  margin: 0 auto;
  text-align: center;

  ${media.lessThan('phone')`
    max-width: 100%;
  `}
`

export { Investors }
