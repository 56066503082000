import React from 'react'
import t from 'prop-types'

const Grapheme = ({ color, strokeWidth }) => (
  <svg width='210' height='211' viewBox='0 0 210 211' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path 
      d='M172.414 111.656C169.034 108.259 169.034 102.728 172.414 99.3315L196.288 75.3365C204.781 66.8011 209.807 54.6947 208.854 41.4561C207.294 18.4192 187.276 1 164.311 1H1V210.031H164.311C187.276 210.031 207.294 192.611 208.854 169.574C209.764 156.336 204.781 144.229 196.288 135.694L172.414 111.656Z' 
      stroke={color || 'var(--color-secondary)'}
      strokeWidth={strokeWidth || 1}
    />
  </svg>
)

Grapheme.propTypes = { 
  color: t.string,
  strokeWidth: t.number,
}

export { Grapheme }
