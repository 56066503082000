import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { useInView } from 'react-intersection-observer'

import { 
  ChaosLine, 
  Text,
  media,
  SectionHeader,
} from 'ui'

const Process = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  return (
    <Wrapper>
      <Inner>
        <SectionHeader
          title='How we work'
        />

        <Content>
          <Image ref={ref} animation={inView}>
            <ChaosLine borderSize='3' />
            <HitArea animation={inView}>
              <Hit>Storming</Hit>
              <Hit>Foundation</Hit>
              <Hit>Delivery</Hit>
            </HitArea>
          </Image>

          <Info>
            <Item animation={inView}>
              <Title>
                Storming
              </Title>
              <Description>
                We help innovators quickly understand how we will build their digital products and provide a competitive business advantage. <strong>The idea that works.</strong>
              </Description>
            </Item>

            <Item animation={inView}>
              <Title>
                Foundation
              </Title>
              <Description>
                We build high-quality digital products that scale. We do that through new technologies, great talents, and true agile methods. <strong>The product that works.</strong>
              </Description>
            </Item>

            <Item animation={inView}>
              <Title>
                Delivery
              </Title>
              <Description>
                We provide full-solution teams to high-growth tech companies using new programming languages to move faster. <strong>The team that works.</strong>
              </Description>
            </Item>
          </Info>
        </Content>

      </Inner>
    </Wrapper>
  )
}

const line = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const show = keyframes`
  to {
    opacity: 1;
  }
`

const Wrapper = styled.section`
  background-image: var(--gradient-section);
  padding: 8rem 0 10rem;

  ${media.lessThan('phone')`
    padding: 6rem 0;
  `}
`

const Inner = styled.div`
  max-width: var(--size-main);
  margin: 0 auto;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10rem;

  ${media.lessThan('phone')`
    display: block;
    margin-top: 5rem;
  `}
`

const Image = styled.div`
  position: relative;
  flex-basis: 57.5%;
  padding-top: 5rem;
  padding-right: 10rem;

  svg {
    width: 100%;
    height: auto;
  }

  ${({ animation }) => animation && css`
    .line1 {
      --line-size: 2900;
      stroke-dasharray: var(--line-size);
      stroke-dashoffset: var(--line-size);
      animation: ${line} 3s linear forwards;
    }

    .line2 {
      --line-size: 1000;
      stroke-dasharray: var(--line-size);
      stroke-dashoffset: var(--line-size);
      animation: ${line} 1s linear forwards 2s;
    }

    .line3,
    .line4,
    .line5,
    .line6 {
      --line-size: 500;
      stroke-dasharray: var(--line-size);
      stroke-dashoffset: var(--line-size);
      animation: ${line} 4s linear forwards 2.8s;
    }

    .line7 {
      --line-size: 500;
      stroke-dasharray: var(--line-size);
      stroke-dashoffset: var(--line-size);
      animation: ${line} 3.5s linear forwards 3.2s;
    }
  `}

  ${media.lessThan('phone')`
    padding-right: 0;
  `}
`

const HitArea = styled.div`
  background: tomato;
  position: relative;
  margin-top: 5rem;

  ${({ animation }) => animation && css`
    span {
      animation: ${show} .5s linear forwards;
      animation-delay: 1s;
    }
  `}

  ${media.lessThan('phone')`
    display: none;
  `}
`

const Hit = styled.span`
  position: absolute;
  display: inline-block;
  top: 0;
  left: 15%;
  background: var(--color-secondary);
  color: var(--color-accent);
  padding: 1rem 1.4rem;
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: -1.8rem;
  border-radius: 50rem;
  opacity: 0;

  &:nth-child(2) {
    left: 48%;
    animation-delay: 2.5s;
  }

  &:nth-child(3) {
    left: 79.5%;
    animation-delay: 3.5s;
  }
`

const Info = styled.div`
  flex: 1;

  ${media.lessThan('phone')`
    padding: 6rem var(--spacing-medium) 0;
  `}
`

const Item = styled.div`
  position: relative;
  margin-bottom: 3rem;
  //opacity: 0;

  /* ${({ animation }) => animation && css`
    animation: ${show} 1s ease forwards;

    &:nth-child(1) {
      animation-delay: 1s;
    }

    &:nth-child(2) {
      animation-delay: 2.4s;
    }

    &:nth-child(3) {
      animation-delay: 3.4s;
    }
  `} */

  ${media.lessThan('phone')`
    text-align: center;
  `}
`

const Title = styled.h3`
  position: relative;
  color: var(--color-primary);
  font-family: var(--font-title);
  font-size: 2.6rem;
  margin: 0;
  padding: 0;
  line-height: 1.6;

  &:after {
    content: '';
    display: block;
    width: 3rem;
    height: 2px;
    background: var(--color-accent);
    margin: .6rem 0 .8rem;

    ${media.lessThan('phone')`
      margin: .6rem auto .8rem;
    `}
  }

  ${media.lessThan('phone')`
    font-size: 2.2rem;
  `}
`

const Description = styled(Text)`
  padding: .3rem 0 0;
  max-width: 80%;

  strong {
    font-weight: 500;
    color: #333;

    ${media.lessThan('phone')`
      display: block;
    `}
  }

  ${media.lessThan('phone')`
    max-width: 100%;
  `}
`

export { Process }
