export const TESTIMONIALS = [
  {
    name: 'Yuri Levenhagen-Serabion',
    role: 'Tech Lead',
    company: 'Pipefy',
    companyImage: 'pipefy',
    avatar: '/images/testimonials/yuri.jpg',
    quote: 'The Brainn’s ability to cope with high-stake requirements is remarkable. The team was often quoted among the most efficient, and managed to execute projects excellently, often helping raise the bar on correct execution.',
    visible: false,
  },
  {
    name: 'Marco Aurélio de Jesus',
    role: 'Business Head',
    company: 'BV',
    companyImage: 'bv',
    avatar: '/images/testimonials/marco.jpg',
    quote: 'When we started our partnership, the high technical level of the entire team quickly became notorious. The concept of quality was always a concern for delivering every product.  With Brainn, the agile method has been taken to another level in our company.',
    visible: true,
  },
  {
    name: 'Flavio Ayra',
    role: 'Director of Engineering',
    company: 'CVC',
    companyImage: 'cvc',
    avatar: '/images/testimonials/flavio.jpg',
    quote: 'We set up a team with Brainn to deal with a great challenge between product and engineering. We were successful in combining the best software development practices, brought by Brainn, along with business knowledge from the internal team.',
    visible: true,
  },
]