import React from 'react'
import styled from 'styled-components'

import { 
  SectionHeader, 
  Text,
  media,
} from 'ui'

const HowWeCanHelp = () => (
  <Wrapper>
    <Inner>
      <SectionHeader
        title='How we can help'
      />

      <List>
        <Item>
          <Icon>
            <img src='/images/services/launch.svg' alt='' />
          </Icon>
          <Content>
            <Title>Launch innovative digital products</Title>
            <Description>
              <strong>Deliver a solution that works.</strong> We help digital innovators - from startups to big companies - bring their ideas to life, launch an entirely new business, or increase their solutions portfolio. Our experts collaborate with you to quickly gather all must-have requirements, choose the right architecture, and design the best user experience. And then, launch continuously.
            </Description>
          </Content>
        </Item>
        <Item>
          <Icon>
            <img src='/images/services/deadline.svg' alt='' />
          </Icon>
          <Content>
            <Title>Meet deadlines within your digital products</Title>
            <Description>
              <strong>Practice a methodology that works.</strong> When business opportunities arise, our clients rely on our disciplined teams working and collaborating with their domain experts - done the right way. Agile metrics and iterative execution are the secret sauce of our teams working with the business people who need answers. Fall in love with predictability, collaboration, noise reduction, and releases again.
            </Description>
          </Content>
        </Item>
        <Item>
          <Icon>
            <img src='/images/services/users.svg' alt='' />
          </Icon>
          <Content>
            <Title>Increase quality to regain trust from your users</Title>
            <Description>
              <strong>Deliver a code that works.</strong> Yeah, we know that. The "let it crash" culture was everywhere, and it is tough to avoid it. We help our clients fix it with extreme programming practices and values, also applying shifting paradigms in computer programming to build resilient and healthy code bases. There is no need to break your users’ passion for your product when they need you the most.
            </Description>
          </Content>
        </Item>
        <Item>
          <Icon>
            <img src='/images/services/archtecture.svg' alt='' />
          </Icon>
          <Content>
            <Title>Improve your architecture and scale faster</Title>
            <Description>
              <strong>Build an architecture that works.</strong> A scalable architecture is essential to allow you to cultivate a growing user base. The higher the number of transactions, the more robust the architecture must be. We work with our global clients breaking their monoliths (and teams) into microservices, applying new paradigms, including event-driven architectures (EDA) and tools like Elixir and Phoenix.
            </Description>
          </Content>
        </Item>
      </List>
    </Inner>
  </Wrapper>
)

const Wrapper = styled.section`
`

const Inner = styled.div`
  padding: 10rem 0 8rem;
  max-width: var(--size-main);
  margin: 0 auto;

  ${media.lessThan('mediumDesktop')`
    padding: 5rem var(--spacing-medium) 4rem;
  `}
`

const List = styled.ul`
  margin: 0;
  padding: 10rem 0 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  ${media.lessThan('tablet')`
    display: block;
    padding: 6rem 0 0;
  `}
`

const Item = styled.li`
  position: relative;
  flex: 50%;
  padding-bottom: 7rem;
  display: flex;

  ${media.lessThan('tablet')`
    padding-bottom: 5rem;
  `}

  ${media.lessThan('phone')`
    display: block;
    text-align: center;
  `}
`

const Content = styled.div`
  flex-basis: auto;
  flex: 1;
  padding-right: 5rem;

  ${media.lessThan('phone')`
    padding-right: 0;
  `}
`

const Icon = styled.div`
  height: 4rem;
  flex-basis: 15%;
  margin-top: .5rem;
  padding-left: .5rem;

  img {
    height: 100%;
    width: auto;
  }

  ${media.lessThan('phone')`
    margin-bottom: 2rem;
  `}
`

const Title = styled.h3`
  margin: 0;
  color: var(--color-primary);
  font-family: var(--font-title);
  font-weight: bold;
  font-size: 2.6rem;
  line-height: 1.3;
  max-width: 90%;

  ${media.lessThan('phone')`
    max-width: 100%;
    font-size: 2.2rem;
  `}
`

const Description = styled(Text)`
  margin: 1rem 0 0 0;
  max-width: 90%;

  strong {
    font-weight: 500;
    color: #333;
  }

  ${media.lessThan('phone')`
    max-width: 100%;
  `}
`

export { HowWeCanHelp }
