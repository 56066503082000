export const PARTNERS_LIST = [
  {
    name: 'Kovi',
    image: 'kovi-black.png',
    url: 'https://www.kovi.com.br',
  },
  {
    name: 'CVC',
    image: 'cvc-black.png',
    url: 'https://www.cvc.com.br',
  },
  {
    name: 'Poliedro',
    image: 'poliedro-black.png',
    url: 'https://www.sistemapoliedro.com.br',
  },
  {
    name: 'BV',
    image: 'bv-black.png',
    url: 'https://www.bv.com.br',
  },
  {
    name: 'Pipefy',
    image: 'pipefy-black.png',
    url: 'https://www.pipefy.com',
  },
  {
    name: 'Find Hotel',
    image: 'findhotel-black.png',
    url: 'https://www.findhotel.net',
  },
  {
    name: 'TalentWall',
    image: 'talentwall-black.png',
    url: 'https://talentwall.io',
  },
  {
    name: 'Benefy',
    image: 'benefy-black.png',
    url: 'https://www.benefy.com.br',
  },
  {
    name: 'Risknow',
    image: 'risknow-black.png',
    url: 'https://www.risknow.ai',
  },
]