import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'

import { media } from 'ui'

const HeaderInternal = ({ title, description }) => (
  <Content>
    <Title>{title}</Title>
    <Description>
      {description}
    </Description>
  </Content>
)

HeaderInternal.propTypes = { 
  title: t.string.isRequired,
  description: t.string,
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: var(--size-main);
  margin: 0 auto;
  width: 100%;
  text-align: center;
  padding: 15rem 0;

  ${media.lessThan('desktop')`
    padding: 10rem var(--spacing-medium) 8rem;
  `}
`

const Title = styled.h2`
  font-size: 6rem;
  color: #000;
  font-family: var(--font-title);
  margin: 0;
  padding: 0;

  ${media.lessThan('phone')`
    font-size: 4.4rem;
    line-height: 1.1;
  `}
`

const Description = styled.p`
  font-size: 2.2rem;
  color: var(--color-text);
  padding: 1rem 0;
  line-height: 1.6;
  max-width: 80rem;
  margin: 0 auto;

  ${media.lessThan('phone')`
    font-size: 1.8rem;
    line-height: 1.4;
  `}
`

export { HeaderInternal }
