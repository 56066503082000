import React from 'react'
import styled, { css } from 'styled-components'

import { media } from 'ui'

const Technologies = () => (
  <Wrapper>
    <Title>Using modern technologies</Title>
    <List>
      <Item>
        <Icon language='elixir'>
          <img src='/images/technologies/elixir.png' alt='Elixir' />
        </Icon>
        <Text>Elixir</Text>
      </Item>
      <Item>
        <Icon>
          <img src='/images/technologies/nodejs.png' alt='NodeJS' />
        </Icon>
        <Text>NodeJS</Text>
      </Item>
      <Item>
        <Icon>
          <img src='/images/technologies/reactjs.png' alt='ReactJS' />
        </Icon>
        <Text>ReactJS</Text>
      </Item>
      <Item>
        <Icon language='typescript'>
          <img src='/images/technologies/ts.png' alt='TypeScript' />
        </Icon>
        <Text>TypeScript</Text>
      </Item>
    </List>
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  max-width: var(--size-main);
  margin: 0 auto;
  padding-top: 8rem;

  ${media.lessThan('phone')`
    padding-top: 4rem;
  `}
`

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
  color: var(--color-secondary);
  font-weight: normal;
  text-align: center;
  letter-spacing: .1em;
  text-transform: uppercase;
`

const List = styled.ul`
  margin: 0 auto;
  padding: 4rem 0 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  max-width: 80rem;

  ${media.lessThan('phone')`
    flex-wrap: wrap;
    padding-left: 3rem;
    padding-right: 3rem;
  `}
`

const Item = styled.li`
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.lessThan('phone')`
    flex-basis: 50%;
    justify-content: flex-start;
    margin-bottom: 2rem;
  `}
`

const Icon = styled.div`
  img {
    max-height: 4rem;
  }

  ${({ language }) => language === 'elixir' && css`
    img {
      max-height: 4.2rem;
    }
  `}

  ${({ language }) => language === 'typescript' && css`
    img {
      max-height: 3.6rem;
    }
  `}

  ${media.lessThan('phone')`
    width: 4rem;
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: auto;
    }
  `}
`

const Text = styled.h4`
  margin: 0;
  padding-left: 1.6rem;
  font-size: 2.4rem;
  font-weight: bold;
  color: var(--color-secondary);

  ${media.lessThan('phone')`
    font-size: 2rem;
    padding-left: 1.2rem;
  `}
`

export { Technologies }
