import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'

import { LogoSymbol, LogoFull } from './logo-svg'

const Logo = ({ color, height = '45px', kind }) => (
  <Wrapper height={height}>
    {kind === 'full' ? <LogoFull color={color} /> : <LogoSymbol color={color} />}
  </Wrapper>
)

Logo.propTypes = { 
  color: t.string,
  height: t.string,
  kind: t.string,
}

const Wrapper = styled.h1`
  position: relative;
  margin: 0;
  padding: 0;
  width: auto;
  height: ${({ height }) => height};
  font-size: 0;
  margin-top: -2px;

  svg {
    width: auto;
    height: 100%;
  }
`

export { Logo }
