import React from 'react'
import styled from 'styled-components'

import { media } from 'ui'

import { PARTNERS_LIST } from 'content'

const Partners = () => (
  <Wrapper>
    <Title>Trusted by global innovators</Title>
    <List>
      {PARTNERS_LIST.map(({ name, image, url }) => (
        <Item href={url} key={name}>
          <img src={`/images/partners/${image}`} alt={name} />
          {name}
        </Item>
      ))}
    </List>
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  max-width: var(--size-main);
  margin: 0 auto;
  padding: 0 0 3rem 0;
`

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
  color: var(--color-text);
  font-weight: normal;
  text-align: center;
  padding-bottom: 1rem;
  letter-spacing: .1em;
  text-transform: uppercase;
`

const List = styled.ul`
  margin: 0;
  padding: 3rem 0 4rem;
  list-style: none;
  display: flex;
  justify-content: center;

  ${media.lessThan('phone')`
    overflow: auto;
    justify-content: flex-start;
    padding: 2rem;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:horizontal {
      height: .5rem;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, .07);
      border: 1px solid #fff;
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, .02);
    }
  `}
`

const Item = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer nofollow',
}))`
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .5;
  transition: opacity .3s ease;
  will-change: opacity;
  text-decoration: none;
  border: 0;
  outline: 0;
  font-size: 0;

  img {
    max-height: 5rem;
    max-width: 100%;
  }

  &:hover {
    opacity: 1;
  }

  ${media.lessThan('phone')`
    min-width: 12rem;

    &:last-child {
      img {
        margin-right: 2rem;
      }
    }
  `}
`

export { Partners }
