import { createGlobalStyle } from 'styled-components'

export const Theme = createGlobalStyle`
  :root {
    --color-primary: #000;
    --color-secondary: #fff;
    --color-accent: #f36;
    --color-text: #777;
    --color-border: #eee;

    --gradient-section: linear-gradient(0deg, hsla(0, 0%, 100%, 0), #f9f9f9);

    --spacing-medium: 3.6rem;

    --font-title: 'Gramatika', Arial, Helvetica, sans-serif;
    --font-default: 'TTInterfaces', Arial, Helvetica, sans-serif;

    --size-main: 1310px;
    --size-inner: calc(var(--size-main) - 3rem);
  }
`