export const MENU = [
  {
    name: 'Home',
    description: 'Home',
    url: '/',
    external: false,
    mainMenu: false,
    footerMenu: false,
  },
  {
    name: 'About',
    description: 'About Brainn Co.',
    url: '/about',
    external: false,
    mainMenu: false,
    footerMenu: false,
  },
  {
    name: 'Join Us',
    description: 'Work with Us',
    url: 'https://apply.workable.com/brainn/',
    external: true,
    mainMenu: false,
    footerMenu: true,
  },
  {
    name: 'Services',
    description: 'Our services',
    url: '/services',
    external: false,
    mainMenu: false,
    footerMenu: false,
  },
]