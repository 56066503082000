import React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import { Menu } from 'partials'

import {
  Logo,
  Button,
  media,
} from 'ui'

const Header = ({ onlyHead, children }) => {
  return (
    <Wrapper onlyHead={onlyHead}>
      <Inner>
        <Top>
          <LogoWrapper>
            <Link to='/'>
              <Logo height='4.8rem' color='#000' />
              Brainn Co.
            </Link>
          </LogoWrapper>
          <Nav>
            <Menu />
          </Nav>
          <CallToAction>
            <Button to='#contact'>
              Say Hello
            </Button>
          </CallToAction>
        </Top>
        {!onlyHead && 
          <Content>
            {children}
          </Content>}
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  height: ${({ onlyHead }) => onlyHead ? 'auto' : '100vh'};

  ${media.lessThan('phone')`
    height: auto;
  `}
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: var(--size-main);
  margin: 0 auto;
  width: 100%;
  height: 100%;

  ${media.lessThan('mediumDesktop')`
    padding: 0 var(--spacing-medium);
  `}
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4rem 0 0;
`

const Content = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.lessThan('phone')`
    padding: 14rem 0;
  `}
`

const Nav = styled.nav`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: auto;
  flex: 1;
  display: none;

  ${media.lessThan('phone')`
    justify-content: flex-end;
  `}
`

const CallToAction = styled.div`
  flex-basis: 20%;
  display: flex;
  justify-content: flex-end;

  ${media.lessThan('phone')`
    flex: 1;
  `}
`

const LogoWrapper = styled.div`
  flex-basis: 20%;
`

const Link = styled(({ ...props }) => <GatsbyLink {...props} />)`
  font-size: 0;
  text-decoration: none;
  display: inline-block;
  outline: 0;
`

export { Header }
