import React from 'react'
import t from 'prop-types'

const ChaosLine = ({ color = '#000', borderSize = '4' }) => (
  <svg width='670px' height='240px' viewBox='0 0 670 240' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-32.000000, -34.000000)' stroke={color} strokeWidth={borderSize}>
        <g transform='translate(34.000000, 36.000000)'>
          <path className='line1' d='M0,132.858 L28.6641,132.858 C65.149,133.479 130.99,130.555 180.72,93.738 C233.051,54.994 189.44,4.043 149.28,0.254 C108.528,-3.59 23.3113,36.252 53.28,117.337 C81.1477,192.7376 176.486,152.6076 191.28,140.778 C218.721,118.836 226.38,77.136 227.76,54.618 C229.054,33.506 223.27,29.904 219.6,28.938 C212.552,27.083 198.436,35.184 209.76,51.018 C218.551,63.311 223.934,65.93 232.08,77.658 C238.919,87.504 256.647,114.755 240.24,149.658 C223.833,184.5608 195.277,186.9112 191.52,174.618 C187.477,161.3889 171.87,68.012 166.08,56.058 C163.503,50.738 144.833,1.652 117.12,14.538 C89.4067,27.424 108.24,70.938 108.24,70.938 C108.24,70.938 123.77,117.577 146.4,135.018 C169.03,152.4587 189.415,124.87 198.96,104.942 C207.018,88.117 211.142,32.338 182.4,44.058 C153.658,55.778 150.629,84.039 152.64,98.778 C154.651,113.517 156.732,107.375 152.668,107.963 C148.603,108.55 118.33,101.239 116.64,75.018 C114.983,49.31 136.341,38.079 155.76,44.538 C175.951,51.254 162.231,94.856 155.76,104.942 C149.328,114.967 154.212,122.932 160.08,128.058 C165.948,133.184 178.247,133.05 190.08,132.858 L266.814,132.858 C266.814,132.858 297.991,129.934 299.52,154.218 L300,215.898 C300,215.898 300.025,236 319.625,236 C339.225,236 338.4,215.898 338.4,215.898 L338.4,47.178 C338.4,47.178 339.6,30.138 357.48,30.138 C375.36,30.138 376.56,47.178 376.56,47.178 L376.56,168.618 C376.56,168.618 374.531,192.618 396.211,192.618 C417.891,192.618 415.92,168.618 415.92,168.618 L415.92,146.058 C415.92,146.058 415.888,132.858 434.67,132.858 C453.3,132.858 453.12,146.058 453.12,146.058 L453.12,168.618 C453.12,168.618 452.45,184.458 473.37,184.458 C494.29,184.458 493.68,168.618 493.68,168.618 L493.68,145.818 C493.68,145.818 492.779,132.858 511.2,132.858' />
          <path className='line2' d='M267.84,132.858 C267.84,132.858 282.96,130.589 282.96,117.337 L282.96,19.578 C282.96,19.578 284.08,4.458 302.28,4.458 C320.48,4.458 321.6,19.578 321.6,19.578 L321.6,191.898 C321.6,191.898 321.193,209.658 341.753,209.658 C362.313,209.658 361.44,191.898 361.44,191.898 L361.44,65.898 C361.44,65.898 361.102,51.03 380.622,51.03 C400.142,51.03 399.36,65.898 399.36,65.898 L399.36,152.298 C399.36,152.298 398.851,168.618 419.131,168.618 C439.411,168.618 438.96,152.298 438.96,152.298 L438.96,106.458 C438.96,106.458 440.036,93.018 458.036,93.018 C476.036,93.018 476.4,106.458 476.4,106.458 L476.4,121.098 C476.4,121.098 475.832,132.858 488.4,132.858 L511.2,132.858' />
          <path className='line3' d='M511.2,132.858012 C511.2,132.858012 531.421,131.313 543.36,117.337 C555.299,103.361 581.519,95.611 602.4,117.337 C617.425,132.971 621.937,132.858012 633.84,132.858012 L665.28,132.858012' />
          <path className='line4' d='M511.2,132.858 C511.2,132.858 526.555,134.67 549.36,121.818 C572.165,108.966 591.11,118.091 599.28,122.778 C602.351,124.54 620.221,132.858 627.6,132.858' />
          <path className='line5' d='M511.2,132.945988 C511.2,132.945988 531.421,134.491 543.36,148.4666 C555.299,162.4423 581.519,170.1928 602.4,148.4666 C617.425,132.833 621.937,132.945988 633.84,132.945988 L665.28,132.945988' />
          <path className='line6' d='M511.2,132.946 C511.2,132.946 526.555,131.134 549.36,143.9857 C572.165,156.8378 591.11,147.7123 599.28,143.0257 C602.351,141.2638 620.221,132.946 627.6,132.946' />
          <line className='line7' x1='511.2' y1='132.858' x2='627.6' y2='132.858' />
        </g>
      </g>
    </g>
  </svg>
)

const ChaosLineFull = ({ color = '#000', borderSize = '2' }) => (
  <svg width='2604' height='240' viewBox='0 0 2604 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1514.2 134.858C1495.78 134.858 1496.68 147.818 1496.68 147.818V170.618C1496.68 170.618 1497.29 186.458 1476.37 186.458C1455.45 186.458 1456.12 170.618 1456.12 170.618V148.058C1456.12 148.058 1456.3 134.858 1437.67 134.858C1418.89 134.858 1418.92 148.058 1418.92 148.058V170.618C1418.92 170.618 1420.89 194.618 1399.21 194.618C1377.53 194.618 1379.56 170.618 1379.56 170.618V49.1779C1379.56 49.1779 1378.36 32.1379 1360.48 32.1379C1342.6 32.1379 1341.4 49.1779 1341.4 49.1779V217.898C1341.4 217.898 1342.23 238 1322.62 238C1303.02 238 1303 217.898 1303 217.898L1302.52 156.218C1300.99 131.934 1269.81 134.858 1269.81 134.858H1193.08C1181.25 135.05 1168.95 135.184 1163.08 130.058C1157.21 124.932 1152.33 116.967 1158.76 106.942C1165.23 96.8559 1178.95 53.2539 1158.76 46.5379C1139.34 40.0789 1117.98 51.3099 1119.64 77.0179C1121.33 103.239 1151.6 110.55 1155.67 109.963C1159.73 109.375 1157.65 115.517 1155.64 100.778C1153.63 86.0389 1156.66 57.7779 1185.4 46.0579C1214.14 34.3379 1210.02 90.1169 1201.96 106.942C1192.41 126.87 1172.03 154.459 1149.4 137.018C1126.77 119.577 1111.24 72.9379 1111.24 72.9379C1111.24 72.9379 1092.41 29.4239 1120.12 16.5379C1147.83 3.65189 1166.5 52.7379 1169.08 58.0579C1174.87 70.0119 1190.48 163.389 1194.52 176.618C1198.28 188.911 1226.83 186.561 1243.24 151.658C1259.65 116.755 1241.92 89.5039 1235.08 79.6579C1226.93 67.9299 1221.55 65.3109 1212.76 53.0179C1201.44 37.1839 1215.55 29.0829 1222.6 30.9379C1226.27 31.9039 1232.05 35.5059 1230.76 56.6179C1229.38 79.1359 1221.72 120.836 1194.28 142.778C1179.49 154.607 1084.15 194.737 1056.28 119.337C1026.31 38.2519 1111.53 -1.59011 1152.28 2.25389C1192.44 6.04289 1236.05 56.9939 1183.72 95.7379C1133.99 132.555 1068.15 135.479 1031.66 134.858H1003H2' stroke={color} strokeWidth={borderSize} />
    <path d='M1270.84 134.858C1270.84 134.858 1285.96 132.589 1285.96 119.337V21.578C1285.96 21.578 1287.08 6.45801 1305.28 6.45801C1323.48 6.45801 1324.6 21.578 1324.6 21.578V193.898C1324.6 193.898 1324.19 211.658 1344.75 211.658C1365.31 211.658 1364.44 193.898 1364.44 193.898V67.898C1364.44 67.898 1364.1 53.03 1383.62 53.03C1403.14 53.03 1402.36 67.898 1402.36 67.898V154.298C1402.36 154.298 1401.85 170.618 1422.13 170.618C1442.41 170.618 1441.96 154.298 1441.96 154.298V108.458C1441.96 108.458 1443.04 95.018 1461.04 95.018C1479.04 95.018 1479.4 108.458 1479.4 108.458V123.098C1479.4 123.098 1478.83 134.858 1491.4 134.858H1514.2' stroke={color} strokeWidth={borderSize} />
    <path d='M1514.2 134.858C1514.2 134.858 1534.42 133.313 1546.36 119.337C1558.3 105.361 1584.52 97.6109 1605.4 119.337C1620.42 134.971 1624.94 134.858 1636.84 134.858H1668.28' stroke={color} strokeWidth={borderSize} />
    <path d='M1514.2 134.858C1514.2 134.858 1529.55 136.67 1552.36 123.818C1575.16 110.966 1594.11 120.091 1602.28 124.778C1605.35 126.54 1623.22 134.858 1630.6 134.858' stroke={color} strokeWidth={borderSize} />
    <path d='M1514.2 134.946C1514.2 134.946 1534.42 136.491 1546.36 150.466C1558.3 164.442 1584.52 172.193 1605.4 150.466C1620.42 134.833 1624.94 134.946 1636.84 134.946H1668.28' stroke={color} strokeWidth={borderSize} />
    <path d='M1514.2 134.946C1514.2 134.946 1529.55 133.134 1552.36 145.986C1575.16 158.838 1594.11 149.712 1602.28 145.026C1605.35 143.264 1623.22 134.946 1630.6 134.946' stroke={color} strokeWidth={borderSize} />
    <path d='M1514.2 134.858H1630.6H2602' stroke={color} strokeWidth={borderSize} />
  </svg>
)

ChaosLine.propTypes = { 
  color: t.string,
  borderSize: t.string,
}

ChaosLineFull.propTypes = { 
  color: t.string,
  borderSize: t.string,
}

export { ChaosLine, ChaosLineFull }