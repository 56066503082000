import React from 'react'
import styled from 'styled-components'

import { Text, media } from 'ui'

import { TESTIMONIALS } from 'content'

const Testimonials = () => (
  <Wrapper>
    <Inner>
      <List>
        {TESTIMONIALS.map(({ 
          name, 
          role, 
          company,
          companyImage, 
          avatar, 
          quote, 
          visible,
        }) => (
          (visible && <Item key={name}>
            <Picture>
              <img src={avatar} alt={name} />
            </Picture>
            <QuoteContent>
              <Quote>
                {quote}
              </Quote>
              <Author>
                <Name>
                  {name}
                </Name>
                <Role>
                  {role} @
                  <img 
                    src={`images/partners/${companyImage}-black.png`}
                    alt={company}
                    className={`company-image__${companyImage}`}
                  />
                </Role>
              </Author>
            </QuoteContent>
          </Item>)
        ))}
      </List>
    </Inner>
  </Wrapper>
)

const Wrapper = styled.section`
`

const Inner = styled.div`
  max-width: var(--size-inner);
  margin: 0 auto;
  width: 100%;
  border-top: 1px solid #eee;
  padding: 10rem 0;

  ${media.lessThan('mediumDesktop')`
    padding: 6rem var(--spacing-medium);
  `}

  ${media.lessThan('phone')`
    padding: 6rem 0;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 4rem;
      bottom: 4rem;
      right: 0;
      width: 1px;
      background: var(--color-secondary);
      box-shadow: -.2rem 0 2rem 2rem var(--color-secondary);
    }
  `}
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;

  ${media.lessThan('phone')`
    overflow: auto;
  `}
`

const Item = styled.li`
  flex-basis: 46%;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;

  ${media.lessThan('phone')`
    display: block;
    min-width: 30rem;
    flex-basis: 50%;
    padding: 0 0 0 var(--spacing-medium);

    &:last-child {
      min-width: 33rem;
      padding-right: 3rem;
    }
  `}
`

const QuoteContent = styled.div`
  position: relative;
  padding-left: 4.4rem;

  ${media.lessThan('phone')`
    padding-left: 0;
  `}
`

const Quote = styled(Text)`
  &:before {
    content: '“';
    font-size: 8rem;
    color: #ccc;
    position: absolute;
    top: -2.8rem;
    left: 0;
    font-family: Georgia, Times, serif;

    ${media.lessThan('phone')`
      display: none;
    `}
  }
`

const Picture = styled.div`
  padding-right: 2rem;

  img {
    max-width: 6rem;
    border-radius: 50%;
  }

  ${media.lessThan('phone')`
    padding-bottom: 1rem;

    img {
      max-width: 5rem;
    }
  `}
`

const Author = styled.div`
  position: relative;
  margin-top: 2rem;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: .9rem;
    right: 103%;
    height: 2px;
    background: #ccc;
    width: 2.6rem;

    ${media.lessThan('phone')`
      display: none;
    `}
  }
`

const Name = styled.h5`
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  color: var(--color-primary);
  line-height: 1.2;
  font-weight: 500;
`

const Role = styled.p`
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  color: var(--color-text);
  line-height: 2;

  img {
    display: inline-block;
    max-height: 2.6rem;
    vertical-align: middle;
    opacity: .8;

    &.company-image__cvc {
      margin-top: -3px;
      margin-left: -10px;
    }

    &.company-image__bv {
      margin-left: -2px;
    }
  }
`

export { Testimonials }